import { render, staticRenderFns } from "./LayoutVerticalProvider.vue?vue&type=template&id=07b95106&"
import script from "./LayoutVerticalProvider.vue?vue&type=script&lang=js&"
export * from "./LayoutVerticalProvider.vue?vue&type=script&lang=js&"
import style0 from "./LayoutVerticalProvider.vue?vue&type=style&index=0&id=07b95106&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports